import React, { useState } from "react";
import { Col, Container, Row, Form, Label, Input, FormGroup } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";

const Card = (props) => {
  return (
    <React.Fragment>
      <Col lg={3} md={6}>
        <div className="contact-info text-center mt-5">
          <div className="icon">
            <i className={props.iconClass}></i>
          </div>
          <div className="mt-4 pt-2">
            <h6 className="fs-17">{props.title}</h6>
            {props.isChildItem.map((item, key) => (
              <p className="text-muted mb-0" key={key}>
                {item}
              </p>
            ))}
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

// Contact Data

const ContactData = [
  {
    title: "Main Office",
    iconClass: "mdi mdi-map-marker text-primary h4",
    isChildItem: ["Izmir Town, Lahore"],
  },
  {
    title: "Phone",
    iconClass: "mdi mdi-phone text-primary h4",
    isChildItem: ["Phone: +92 331-447-7209"],
  },
  // {
  //   title: "Contact",
  //   iconClass: "mdi mdi-email text-primary h4",
  //   isChildItem: ["www.corvixsystems.com", "mustansar@corvixsystems.com"],
  // },
  {
    title: "Working Hours",
    iconClass: "mdi mdi-calendar-clock text-primary h4",
    isChildItem: ["Monday-Friday: 9:00-06:00", "Saturday-Sunday: Holiday"],
  },
];

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    phoneNumber: "",
  });
  const [status, setStatus] = useState("");
  const [captchaToken, setCaptchaToken] = useState(null);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError("");

    if (!captchaToken) {
      setError("Please complete the CAPTCHA.");
      return;
    }

    console.log("Form is submitted.");

    try {
      const response = await fetch(
        "https://vhbpolr1gi.execute-api.eu-north-1.amazonaws.com/corvix-email-stage/send-email", // Replace with your API Gateway URL
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        setStatus("Message sent successfully!");
        setFormData({ name: "", email: "", subject: "", message: "" });
      } else {
        setStatus("Failed to send the message.");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setStatus("An error occurred. Please try again later.");
    }
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token); // Save the CAPTCHA token
  };

  return (
    <React.Fragment>
      <section className="section" id="contact">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="text-center mb-4">
                <p className="text-uppercase text-muted mb-2">Contact</p>
                <h3 className="text-uppercase">Get In Touch</h3>
                <div className="title-border mt-3"></div>
                <p className="title-desc text-muted mt-3">
                  Ready to transform your ideas into reality? Whether you need
                  innovative mobile apps, advanced AI integration, or
                  cutting-edge cloud solutions, we're here to help. Reach out to
                  us today, and let's discuss how Corvix Systems can elevate
                  your business with tailored technology solutions.
                </p>
              </div>
            </Col>
          </Row>

          {/*  */}

          <Row className="justify-content-center">
            {/* Contact Component Start */}

            {ContactData.map((value, idx) => (
              <Card
                key={idx}
                title={value.title}
                iconClass={value.iconClass}
                isChildItem={value.isChildItem}
              />
            ))}
            {/* Contact Component End */}
          </Row>

          {/* {
            <Row className="mt-5 pt-2 justify-content-center">
              <form onSubmit={handleSubmit}>
                <label>
                  Name:
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </label>
                <label>
                  Email:
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </label>
                <label>
                  Subject:
                  <input
                    type="text"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                  />
                </label>
                <label>
                  Message:
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </label>
                <button type="submit">Send</button>
              </form>
              {status && <p>{status}</p>}
            </Row>
          } */}

          <Row className="mt-5 pt-2 justify-content-center">
            <Col lg={9}>
              <div className="custom-form">
                <Form name="myForm" onSubmit={handleSubmit}>
                  <p id="error-msg"></p>
                  <Row>
                    <Col lg={12}>
                      <FormGroup>
                        <div className="mb-3">
                          <Label for="name" className="form-label">
                            Name* :
                          </Label>
                          <Input
                            name="name"
                            id="name"
                            type="text"
                            className="form-control"
                            placeholder="Enter your name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12}>
                      <FormGroup>
                        <div className="mb-3">
                          <Label for="email" className="form-label">
                            Email address* :
                          </Label>
                          <Input
                            type="email"
                            className="form-control"
                            name="email"
                            id="email"
                            placeholder="Enter your email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </FormGroup>
                    </Col>

                    {/* <Col lg={6}>
                      <FormGroup>
                        <div className="mb-3">
                          <Label for="number" className="form-label">
                            Phone number* :
                          </Label>
                          <Input
                            className="form-control"
                            name="number"
                            id="number"
                            placeholder="Enter your number"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </FormGroup>
                    </Col> */}

                    <Col lg={12}>
                      <FormGroup>
                        <div className="mb-3">
                          <Label for="message" className="form-label">
                            Comments :
                          </Label>
                          <Input
                            type="textarea"
                            className="form-control"
                            placeholder="Leave a comment here"
                            name="message"
                            id="message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                            style={{ height: "100px" }}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12}>
                      <ReCAPTCHA
                        sitekey="6LfNqaMqAAAAADy93nRkcRHDaZJC1anUmAJ5RbEs" // Replace with your Google reCAPTCHA site key
                        onChange={handleCaptchaChange}
                      />
                      {error && <p style={{ color: "red" }}>{error}</p>}
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12}>
                      <FormGroup>
                        <div className="mt-3 text-end">
                          <input
                            type="submit"
                            id="submit"
                            name="send"
                            className="submitBnt btn btn-primary"
                            value="Send Message"
                          />
                          <div id="simple-msg"></div>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Contact;
